<template>
  <div class="pt-3">
    <toolbar-floating
      class="ml-5 pl-5"
      toolbar-id="app-instances"
    >
      <search
        v-model="search"
      ></search>
      <button-add
        label="Add App Instance"
        @click="onCreateAppInstance"
      ></button-add>
    </toolbar-floating>
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-layout row>
        <v-flex
          xs12
          md4
        >
          <selector-card
            v-for="appInstance in appInstances"
            :key="appInstance.id"
            :title="appInstance.description"
            :sub-title="appInstance.environmentId"
            :active="activeAppInstanceId === appInstance.id"
            @click.native="activeAppInstanceId = appInstance.id"
          ></selector-card>
        </v-flex>
        <v-flex
          xs6
          md4
        >
          <v-card
            v-if="activeAppInstance"
            class="app-instance mb-4"
          >
            <v-card-title>
              <h3>Basic settings</h3>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-layout
                row
                wrap
              >
                <v-flex xs12>
                  <v-text-field
                    v-model="activeAppInstance.environmentId"
                    label="Environment Id"
                    :disabled="editMode"
                    required
                    prepend-icon="vpn_key"
                  ></v-text-field>
                  <v-text-field
                    v-model="activeAppInstance.description"
                    label="Description"
                    required
                    prepend-icon="label"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>

          <div v-if="activeAppInstance && editMode">
            <div class="mb-4">
              App Instance Groups
            </div>

            <v-card
              v-for="appInstanceGroup in allAppInstanceGroups"
              :key="appInstanceGroup.id"
              class="app-instance-group mb-4"
            >
              <v-divider></v-divider>
              <v-card-text>
                <v-layout
                  row
                  wrap
                >
                  <v-flex xs12>
                    <v-text-field
                      v-if="newAppInstanceGroups[getNewAppInstanceGroupById(appInstanceGroup.id)]"
                      v-model="newAppInstanceGroups[getNewAppInstanceGroupById(appInstanceGroup.id)].groupId"
                      label="Group Id"
                      required
                      :rules="rules.groupId"
                      prepend-icon="vpn_key"
                    ></v-text-field>
                    <v-text-field
                      v-else-if="allAppInstanceGroups[getAllAppInstanceGroupById(appInstanceGroup.id)]"
                      v-model="allAppInstanceGroups[getAllAppInstanceGroupById(appInstanceGroup.id)].groupId"
                      label="Group Id"
                      required
                      :rules="rules.groupId"
                      prepend-icon="vpn_key"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <button-confirm
                :message="`Do you really want to delete the group: ${appInstanceGroup.groupId}`"
                @confirmed="onAppInstanceGroupDelete(appInstanceGroup)"
              >
              </button-confirm>
            </v-card>

            <item-adder
              name="group"
              @add="onCreateAppInstanceGroup"
            ></item-adder>
          </div>
        </v-flex>
        <v-flex v-if="activeAppInstanceId">
          <v-btn
            class="primary"
            :loading="loadingSave"
            :disabled="loadingSave || !valid"
            @click="onSubmit"
          >
            Save {{ activeAppInstance ? activeAppInstance.environmentId : '' }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ButtonAdd from '../../shared/components/layout/ButtonAdd'
import { _sortBy } from '@/shared/utility/_sort.ts'
import uuid from 'uuid/v4'
import ToolbarFloating from "@/shared/components/layout/ToolbarFloating";
import Search from "@/modules/categories/Search"
import _search from "@/shared/utility/_search"
import ItemAdder
  from "@/modules/navigation/ItemAdder"

export default {
  name: 'AppInstances',
  components: {
    ItemAdder,
    Search,
    SelectorCard: () => import('../../shared/components/layout/SelectorCard'),
    ButtonConfirm: () => import('../../shared/components/layout/ButtonConfirm'),
    ButtonAdd,
    ToolbarFloating,
  },
  data() {
    return {
      activeAppInstanceId: null,
      newAppInstances: [],
      newAppInstanceGroups: [],
      savedAppInstanceGroups: [],
      deletedAppInstanceGroups: [],
      valid: false,
      search: '',
      loadingSave: false,
      rules: {
        environmentId: [
          v => !!v || 'Environment ID is required',
        ],
        description: [
          v => !!v || 'Description is required',
        ],
        groupId: [
          v => !!v || 'Group ID is required',
          v => !/[^A-Za-z0-9]/.test(v) || 'Group ID must contains only alphanumerics',
          v => this.newAppInstanceGroups.filter(group => group.groupId === v).length <= 1 || 'Group ID already exists',
          v => this.allAppInstanceGroups.filter(group => group.groupId === v).length <= 1 || 'Group ID already exists',
        ],
      },
    }
  },
  computed: {
    ...mapGetters([
      'getMercuryAppInstances',
      'getMercuryAppInstanceGroups',
    ]),
    editMode() {
      return this.$store.state.mercuryAppInstances.order.includes(this.activeAppInstance.id)
    },
    appInstances() {
      // return _sortBy(
      return _search({
        search: this.search,
        candidates: [
          ...this.newAppInstances,
          ...this.getMercuryAppInstances,
        ],
        keys: ['description', 'environmentId'],
      })
      // 'description',
      // )
      //     {
      //       search:
      //     }
      //   _search(
      //
      //     [
      //       ...this.newAppInstances,
      //       ...this.getMercuryAppInstances,
      //     ],
      //   ),
      //   'description',
      // )
    },
    activeAppInstance() {
      return this.appInstances.find(el => el.id === this.activeAppInstanceId)
    },
    fetchedAppInstanceGroups() {
      return _sortBy([...this.getMercuryAppInstanceGroups.filter(el => el.groupId.toUpperCase() !== 'DEFAULT')], 'groupId')
    },
    allAppInstanceGroups() {
      return this.newAppInstanceGroups.concat(this.savedAppInstanceGroups)
    },
  },
  watch: {
    async activeAppInstance(instance) {
      if (instance) {
        await this.resetAppInstanceGroups(instance)
      }
    },
  },
  created() {
    this.fetchMercuryAppInstances()
  },
  methods: {
    ...mapActions([
      'createMercuryAppInstance',
      'updateMercuryAppInstance',
      'deleteMercuryAppInstance',
      'fetchMercuryAppInstances',
      'fetchMercuryAppInstanceGroups',
      'createMercuryAppInstanceGroup',
      'deleteMercuryAppInstanceGroup',
    ]),
    async resetAppInstanceGroups(activeAppInstance) {
      this.deletedAppInstanceGroups.splice(0)
      this.savedAppInstanceGroups.splice(0)
      this.newAppInstanceGroups.splice(0)
      await this.fetchMercuryAppInstanceGroups(activeAppInstance)
      this.savedAppInstanceGroups = this.fetchedAppInstanceGroups
    },
    onCreateAppInstance() {
      const noNameInstances = this.newAppInstances.filter(el => el.environmentId.includes('New Instance')).length

      let newAppInstance = {
        id: uuid(),
        environmentId: `New Instance${noNameInstances ? ' (' + noNameInstances + ')' : ''}`,
        description: '',
      }
      this.newAppInstances.push(newAppInstance)
      this.activeAppInstanceId = newAppInstance.id
    },
    onCreateAppInstanceGroup() {
      const noNameInstanceGroups = this.allAppInstanceGroups.filter(el => el.groupId.includes('newGroup')).length

      let newAppInstanceGroup = {
        id: uuid(),
        instanceName: this.activeAppInstance.environmentId,
        groupId: `newGroup${noNameInstanceGroups ? noNameInstanceGroups : ''}`,
        groupName: 'newGroup',
      }

      this.newAppInstanceGroups.push(newAppInstanceGroup)
    },
    async onSubmit() {
      if (this.$refs.form.validate()) {
        this.loadingSave = true

        if (this.editMode) {
          // Handle deleted app instance group
          for (const entry of this.deletedAppInstanceGroups) {
            await this.deleteMercuryAppInstanceGroup(entry)
          }
          // Handle new app instance group
          for (const entry of this.newAppInstanceGroups) {
            await this.createMercuryAppInstanceGroup(entry)
          }
          // Handle app instance
          await this.updateMercuryAppInstance(this.activeAppInstance)
        } else {
          // Handle app instance
          let newAppInstance = JSON.parse(JSON.stringify(this.activeAppInstance))
          newAppInstance.id = this.activeAppInstance.environmentId
          let result = await this.createMercuryAppInstance(newAppInstance)
          this.newAppInstances.splice(this.newAppInstances.indexOf(newAppInstance), 1)
          this.newAppInstances = this.newAppInstances.filter(el => el.environmentId !== result.environmentId)
          this.activeAppInstanceId = result.id
          // Handle new app instance group
          for (const entry of this.newAppInstanceGroups) {
            await this.createMercuryAppInstanceGroup(entry)
          }
        }

        await this.resetAppInstanceGroups(this.activeAppInstance)

        this.loadingSave = false
      }
    },
    getAllAppInstanceGroupById(groupId) {
      return this.allAppInstanceGroups.findIndex(group => group.id === groupId)
    },
    getNewAppInstanceGroupById(groupId) {
      return this.newAppInstanceGroups.findIndex(group => group.id === groupId)
    },
    onAppInstanceGroupDelete(appInstanceGroup) {
      let newGroupDeleteIndex = this.newAppInstanceGroups.indexOf(appInstanceGroup)
      let savedGroupDeleteIndex = this.savedAppInstanceGroups.indexOf(appInstanceGroup)

      if (newGroupDeleteIndex >= 0) {
        this.newAppInstanceGroups.splice(newGroupDeleteIndex, 1)
      }
      if (savedGroupDeleteIndex >= 0) {
        this.savedAppInstanceGroups.splice(savedGroupDeleteIndex, 1)
        this.deletedAppInstanceGroups.push(appInstanceGroup)
      }
    },
  },

}
</script>

<style lang="scss">
  .v-carousel__next,
  .v-carousel__prev {

    .v-btn__content {
      text-shadow: 1px 1px 8px #000;
    }

  }
</style>
