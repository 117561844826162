<template>
  <v-text-field
    clearable
    flat
    solo-inverted
    hide-details
    prepend-inner-icon="search"
    label="Search"
    :value="value"
    @input="$emit('input', $event)"
  ></v-text-field>
</template>

<script>
export default {
  name: 'Search',
  props: {
    value: {
      type: String,
      default: null,
    },
  },
}
</script>
