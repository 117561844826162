/**
 * Universal, recursive search. Omits &shy;, &nbsp; and <br>.
 * Searches for a string or an array of strings in all candidates (if candidates are objects, key must be specified).
 * All supplied searchTerms must be present in results
 *
 * @param {object} searchConfig
 * @param {string | array<string>} searchConfig.search
 * @param {number} searchConfig.limit
 * @param {array<string> | array<object>}  searchConfig.candidates
 * @param {array<string>} searchConfig.key
 *
 * @returns {array}
 */
function _search({search, limit, candidates, key, keys}) {
  // he who gives null
  // shall receive null
  if (typeof search === 'undefined' || search === null) {
    console.log('Go null yourself!')
    return null
  }

  // he who cannot decide
  // shall not prevail
  if (typeof key !== 'undefined' && typeof keys !== 'undefined') {
    console.log('Use either "key" or "keys", not both!')
    return null
  }

  const searchTerms = typeof search === 'string' ? search.split(' ') : search

  if (searchTerms.length === 0) {
    return candidates.slice(0, limit || candidates.length)
  } else {
    let newCandidates = []

    // if key is used or no key at all
    // (for backwards compat)
    if (typeof keys === 'undefined') {
      newCandidates = candidates
        .filter(el => (key ? el[key] : el)
          .split('&shy;').join()
          .split('&nbsp;').join()
          .split('<br>').join()
          .toUpperCase()
          .includes(searchTerms[0].toUpperCase()),
        )
    } else {
      newCandidates = candidates
        .filter(candidate => (
          keys.reduce((sum, key) => {
            sum += ` ${candidate[key]}`
            return sum
          }, '')
            .split('&shy;').join()
            .split('&nbsp;').join()
            .split('<br>').join()
            .toUpperCase()
            .includes(searchTerms[0].toUpperCase())
        ))
    }

    return newCandidates.length && searchTerms.length
      ? _search({
        search: searchTerms.splice(1),
        candidates: newCandidates,
        limit,
        key,
        keys,
      })
      : newCandidates
  }
}

export default _search
